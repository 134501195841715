<template>
  <v-list class="faq-category-list pa-4 pa-sm-0">
    <v-list-item
      v-for="item in content"
      :key="item.id"
      class="faq-category-list-item"
    >
      <a
        class="faq-category-list-item-link"
        :class="{ active: item.slug === active }"
        @click="filterByCategory(item.slug)"
        v-html="item.name"
      ></a>
    </v-list-item>
  </v-list>
</template>
<style scoped lang="scss">
.faq-category-list-item {
  min-height: 20px;
  padding: 0 16px 0 0;
}
.faq-category-list-item-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--v-primary-base);
  text-decoration: none;
  &.active {
    font-weight: 700;
    color: var(--v-primary-base);
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
export default {
  name: "FaqCategory",
  data() {
    return {
      content: null,
      active: null
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      let res = await CMSService.getCustomPostByFilters("categoria_faq");
      this.content = res.data;
    },
    filterByCategory(slug) {
      this.active = slug;
      this.$emit("filter", slug);
    }
  }
};
</script>
