<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="3"
        order="last"
        order-md="first"
        class="pa-0 pa-md-3"
      >
      </v-col>
      <v-col cols="12" md="9">
        <div v-html="content" @click="clicked"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

import { mapGetters } from "vuex";
export default {
  name: "PageTwoColumn",
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    content() {
      return get(this.page, "content.rendered");
    },
    showBeCustomerCard() {
      return this.page.slug != "codice-etico";
    }
  }
};
</script>
