<template>
  <div>
    <div v-html="content" @click="clicked"></div>
    <form-servizio-clienti :showPrivacy="false" />
  </div>
</template>
<script>
import FormServizioClienti from "./FormServizioClienti";

import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "PageContacts",
  components: {
    FormServizioClienti
  },
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return get(this.page, "content.rendered");
    }
  }
};
</script>
