<template>
  <div class="header-banner">
    <div class="d-flex justify-center">
      <div
        class="text-h4 primary--text text--lighten-1 font-weight-bold my-6"
        v-html="title"
        v-if="title"
      ></div>
    </div>
    <v-row no-gutters justify="space-around">
      <v-col
        v-for="proposal in proposals"
        :key="proposal.id"
        class="d-flex px-4 justify-center"
        cols="6"
        sm="4"
        md="3"
        lg="2"
      >
        <ProposalCard :proposal="proposal" :showContent="showProposalContent" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalCard from "./ProposalCard";
export default {
  name: "ProposalCustomGrid",
  props: {
    title: { type: String, required: false },
    proposals: { type: Array, required: true },
    showProposalContent: { type: Boolean, required: false, default: false }
  },
  components: { ProposalCard },
  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
h2 {
  max-width: 500px;
  font-size: 26px;
  line-height: 28px;
  color: $secondary;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
</style>
