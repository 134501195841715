<template>
  <div>
    <div v-html="content" @click="clicked"></div>
    <v-container class="px-0 px-sm-3 my-16">
      <ProposalCustomGrid
        :title="$t('headerBanner.title')"
        :proposals="headerBanner"
        :showProposalContent="true"
      />
    </v-container>
  </div>
</template>
<script>
import ProposalCustomGrid from "@/components/proposal/ProposalCustomGrid";
import category from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import { mapProposal } from "~/service/ebsn";
import get from "lodash/get";

export default {
  name: "PageAssortment",
  components: { ProposalCustomGrid },
  mixins: [category, clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    }),
    content() {
      return get(this.page, "content.rendered");
    }
  },
  mounted() {
    this.setCategory({ slug: "home-new" });
  }
};
</script>

<style scoped lang="scss">
.header-banner {
  background-color: #ddf0ee;
  padding: 66px $home-wrapper-horizontal-padding;
  h2 {
    max-width: 500px;
    font-size: 26px;
    line-height: 28px;
    color: $secondary;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 22px;
      margin-bottom: 30px;
    }
  }
  .proposal-card {
    .v-card__text {
      color: $card-text-color;
      text-transform: uppercase;
    }
  }
}

.footer-cards-wrapper {
  margin-top: 70px;
  margin-bottom: 70px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .v-card {
    max-width: 415px;
  }
  .v-card__title {
    padding: 16px !important;
    font-weight: bold !important;
  }
  .v-card__subtitle {
    min-height: 80px;
    padding: 16px !important;
    padding-bottom: 20px !important;
  }
  .v-card__actions {
    align-items: flex-end;
    height: 100%;
    padding-bottom: 25px;
  }

  .servizi-spesa {
    background-color: $secondary;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .header-banner {
    padding: 30px 20px;
    h2 {
      font-size: 22px;
      margin-bottom: 30px;
    }
    .col-6 {
      padding: 6px !important;
    }
    // .v-card__text {
    //   font-size: 0.6em;
    // }
  }
}
</style>
