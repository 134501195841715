<template>
  <v-container fluid>
    <div v-html="content" @click="clicked" class="content mx-4"></div>
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "PageFullWidth",
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return get(this.page, "content.rendered");
    }
  }
};
</script>
